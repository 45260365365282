import { Button, Checkbox, CircularProgress, Container, FormControlLabel, Grid, Link, TextField, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ConfigContext } from "../App";
import { post, postRaw } from "../utlis/genericRepository";
import { history } from "../App";
import { useLocation } from "react-router-dom";

const Im = styled.img`
  height: 300px;
  width: 300px;
  object-fit: scale-down;
  margin-top: 2rem;
`

type SignUpPageState = {
    userName: string,
    password: string,
    password2: string,
    loading: boolean,
    first_name: string,
    last_name: string,
    gdpr_consent: boolean,
    error: string,
    code: string,
    email: string,
    success: boolean
};

export function SignUp() {

    const s = useLocation()
    const query = new URLSearchParams(s.search);
    const dashboardValue = query.get("code");


    const config = useContext(ConfigContext);
    const [state, setState] = useState<SignUpPageState>({
        userName: "",
        password: "",
        password2: "",
        loading: false,
        first_name: "",
        last_name: "",
        gdpr_consent: false,
        error: "",
        code: dashboardValue ?? "",
        email: "",
        success: false
    });

    if (state.loading) return <Grid container justify="center" style={{ marginTop: "2rem" }} xs={12}>
        <CircularProgress />
    </Grid>

    if (state.success) return <Container maxWidth="xs">
        <Grid item xs={12} style={{marginTop: "2rem"}}>
            <Typography variant="h2">Congratulazioni!</Typography>
            <Typography style={{marginTop: "2rem"}} >La registrazione è stata completata! Controlla la mail per impostare una password!</Typography>
        </Grid>

        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: "2rem"}}>
            <Button onClick={() => history.push("/login")}>
                Login
                    </Button>
        </Grid>

    </Container>

    return <>
        <Container maxWidth="sm">
            <Grid container>
                <Grid container xs={12} justify="center">
                    <Im src={config?.logo_url} />
                </Grid>

                <Grid container justify="center" style={{ marginTop: "2rem" }} xs={12}>
                    <TextField
                        id="email"
                        error={(() => {
                            var re = /\S+@\S+\.\S+/;
                            return re.test(state.email);
                        })()}
                        label="Email"
                        style={{ width: 280 }}
                        value={state.userName}
                        onChange={(e) => { setState({ ...state, userName: e.target.value, email: e.target.value }) }}
                    />
                </Grid>
                <Grid container justify="center" xs={12}>
                    <TextField
                        id="first_name"
                        label="Nome"
                        style={{ width: 280 }}
                        value={state.first_name}
                        onChange={(e) => { setState({ ...state, first_name: e.target.value }) }}
                    />
                </Grid>
                <Grid container justify="center" xs={12}>
                    <TextField
                        id="last_name"
                        label="Cognome"
                        style={{ width: 280 }}
                        value={state.last_name}
                        onChange={(e) => { setState({ ...state, last_name: e.target.value }) }}
                    />
                </Grid>
                <Grid container justify="center" xs={12}>
                    <TextField
                        id="code"
                        label="Codice di invito"
                        style={{ width: 280 }}
                        value={state.code}
                        onChange={(e) => { setState({ ...state, code: e.target.value }) }}
                    />
                </Grid>
                {/* <Grid container justify="center" xs={12}>
                    <TextField
                        id="password"
                        label="password"
                        style={{ width: 280 }}
                        value={state.password}
                        onChange={(e) => { setState({ ...state, password: e.target.value }) }}
                        type="password"
                    />
                </Grid>
                <Grid container justify="center" xs={12}>
                    <TextField
                        id="password_again"
                        label="password (di nuovo)"
                        error={state.password != state.password2}
                        style={{ width: 280 }}
                        helperText={state.password != state.password2 ? "Le due password devono coincidere" : undefined}
                        value={state.password2}
                        onChange={(e) => { setState({ ...state, password2: e.target.value }) }}
                        type="password"
                    />
                </Grid> */}
                <Grid container justify="center" xs={12}>
                    <div style={{ width: 280, display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <Link href={config.privacy_link} target="_blank" color="inherit">
                            Accetto i termini e condizioni
                    </Link>
                        <Checkbox
                            checked={state.gdpr_consent}
                            onChange={(c) => setState({ ...state, gdpr_consent: !state.gdpr_consent })}
                            name="gdpr"
                            color="primary"
                        />
                    </div>

                </Grid>
                {state.error != "" && <Grid container justify="center" style={{ marginTop: "2rem" }} xs={12}>
                    <Typography variant="h4" color="error">
                        Credenziali Errate
                    </Typography>
                </Grid>}
                <Grid container justify="center" style={{ marginTop: "2rem" }} xs={12}>
                    <Button variant="contained" disabled={!stateIsValid(state)} color="primary" onClick={() => doSignUp(state, setState, config)}>
                        Registrati
                    </Button>
                </Grid>
                <Grid container justify="center" style={{ marginTop: "1rem" }} xs={12}>
                    <Button onClick={() => history.push("/login")}>
                        Login
                    </Button>
                </Grid>

            </Grid>

        </Container>
    </>
}

function stateIsValid(state: SignUpPageState) {
    var re = /\S+@\S+\.\S+/;
    return state.gdpr_consent && state.email != "" && re.test(state.email) && state.first_name != "" && state.last_name != "" && state.code != ""
}

async function doSignUp(state: SignUpPageState, setState: React.Dispatch<React.SetStateAction<SignUpPageState>>, config: any): Promise<string | true> {
    let ns = state;
    state = { ...state, loading: true };
    setState(state);

    const response = await postRaw({
        username: state.userName,
        password: state.password,
        email: state.email,
        first_name: state.first_name,
        last_name: state.last_name,
        code: state.code
    }, `/api/v1/partner/${config.partner_id}/sign-up`);

    if (response.status == 200) {
        state = { ...state, loading: false, success: true };
        setState(state)

    } else if (response.status == 422) {
        state = { ...state, loading: false, error: "Impossibile creare l'utente, se sei gia registrato vai al login" };
        setState(state)
    } else {
        state = { ...state, loading: false, error: "Impossibile creare l'utente" };
        setState(state)
    }

    return response.status == 200 ? true : "Errore nella registrazione";
}
