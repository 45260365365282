
import { BASE_URL,} from "../App";
import { Condominium, SubCondominium, Immobile } from "../models/condominium";

import { Meter } from "../models/meter";
import { fetchPaginatedCollection, postRaw, remoteFetchAttributes, remoteFetchData } from "./genericRepository";


export async function fetchCondominiumMeters(condominium_id: number, sub_condominium_id?: string, immobile_id?: string): Promise<Meter[] | string> {

    const filter = `?condominium_id=${condominium_id}${sub_condominium_id ? `&sub_condominium_uuid=${sub_condominium_id}`: ''}${immobile_id ? `&immobile_uuid=${immobile_id}`: ''}&per_page=80000`
    const url = `/api/v1/meter-alarms${filter}`
    return remoteFetchData(url);
}

export async function fetchCondominium(id: number | string): Promise<Condominium | string> {
    try {
        return fromJson(await remoteFetchAttributes<any>(`/api/v1/condominium/${id}`));
    } catch(e) {
        return e.toString();
    }
}

export async function fetchCondominiums(): Promise<Condominium[] | string> {
    try {
        const r = await fetchPaginatedCollection<any>(
            `/api/v1/condominium`,
            undefined
            , (items) => {
                // Pre Process
                return items.map((i) => {
                    return fromJson(i);
                })
            })(1, 800);
            if(typeof r != "string") return r.sublist
            return r
    } catch(e) {
        return e.toString();
    }
}



function toJson(c: Condominium): any {
    return {
        "data": {
            "type": "condominium",
            "attributes": {
                "code": c.node_id,
                "name": c.data.cond_name ?? '',
                "address": c.data.address,
                "city": c.data.city,
                "zip": c.data.postalCode,
                "companyName": c.data.companyName,
                "vatNumber": c.data.vatNumber,
                "province": c.data.province,
                "updateGroups": c.updateGroups,
                "listGroups": c.listGroups,
                "deleteGroups": c.deleteGroups,
                "region": c.data.province,
                "managedBy": c.managedBy ?? null,
                "status": c.status ?? "disabled",
                "subCondominiums": [
                    ...c.children.filter((c: any) => c.type === "SUBCONDOMINIUM").map((c: any) => toJsonSc(c as any))
                ]
            }
        }
    }
}

function toJsonSc(sc: SubCondominium): any {
    return {
        "uuid": sc.node_id,
        "stair": sc.data.stair,
        "address": sc.data.address,
        "number": sc.data.number,
        "name": sc.data.name ?? '',
        "immobiles": [
            ...sc.children.filter((c: any) => c.type === "IMMOBILE").map((c: any) => toJsonIm(c as any))
        ]
    }
}

function toJsonIm(i: Immobile): any {
    return {
        "uuid": i.node_id,
        "code": i.node_id,
        "floor": i.data.floor,
        "name": i.data.name ?? '',
        "location": i.data.location,
        "internal": i.data.flatNumber,
        "cadastral_identification": i.data.identifier,
        "tenant_first_name": i.tenant.firstName,
        "tenant_last_name": i.tenant.lastName,
        "tenant_email": i.tenant.email,
        "tenant_phone_number": i.tenant.phoneNumber,
        "tenant_fax": i.tenant.fax,
        "tenant_vat_number": i.tenant.vatNumber,
        "owner_first_name": i.owner.firstName,
        "owner_last_name": i.owner.lastName,
        "owner_email": i.owner.email,
        "owner_phone_number": i.owner.phoneNumber,
        "owner_fax": i.owner.fax,
        "owner_vat_number": i.owner.vatNumber
    }
}
 

export function fromJson(json: any): Condominium | string {
    try {
        return {
            type: "condominium",
            id: json.id,
            path_url: "/",
            status: json.status,
            node_id: `${json.id}`,
            
            insertedAt: (() => {
                try { return new Date(json.insertedAt) } catch(e) { return undefined; }
            })(),
            availableTabs: ["data", "readings", "consumption", "alarms", "self_reading"],
            data: {
                companyName: json.companyName,
                vatNumber: json.vatNumber,
                address: json.address,
                city: json.city,
                cond_name: json.name,
                province: json.province,
                postalCode: json.zip
            },
            managedBy: json.managedBy,
            children: (json.subCondominiums ?? []).map(fromJsonSc),
        }
    } catch (e) {
        return e.toString();
    }
}

function fromJsonSc(json: any): SubCondominium {
    return {
        path_url: `/${json.uuid}`,
        node_id: json.uuid,
        id: json.uuid,
        type: "subcondominium",
        data: {
            stair: json.stair,
            address: json.address,
            number: json.number,
            name: json.name ?? ''
        },
        availableTabs: ["data", "readings", "consumption", "alarms", "self_reading"],
        children: (json.immobiles ?? []).map((i: any) => fromJsonIm(i, `/${json.id}`)),
    }
}


function fromJsonIm(json:any, pathPrefix: string): Immobile {

    return {
        path_url: `${pathPrefix}/${json.uuid}`,
        node_id: json.uuid,
        id: json.uuid,
        type: "immobile",
        data: {
            floor: json.floor,
            location: json.location,
            flatNumber: json.internal,
            name: json.name,
            identifier: json.cadastral_identification
        },
        tenant: {
            firstName: json.tenant_first_name,
            lastName: json.tenant_last_name,
            email: json.tenant_email,
            phoneNumber: json.tenant_phone_number,
            fax: json.tenant_fax,
            vatNumber: json.tenant_vat_number,
        },
        owner: {
            firstName: json.owner_first_name,
            lastName: json.owner_last_name,
            email: json.owner_email,
            phoneNumber: json.owner_phone_number,
            fax: json.owner_fax,
            vatNumber: json.owner_vat_number,
        },
        availableTabs: ["data", "readings",  "consumption", "alarms", "self_reading"],
        children: []
    }
}

