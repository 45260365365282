import { colors } from "@material-ui/core";


function someColorStuff(color: string, initialValue: string[], iteration = 0): string[] {
    try {
        const [rs, gs, bs] = [color.substring(1, 3), color.substring(3, 5), color.substring(5, 7)];
        const [r, g, b] = [parseInt(rs, 16), parseInt(gs, 16), parseInt(bs, 16)];


        const nc = `#${decimalToHex(Math.max(r - 30, 0))}${decimalToHex(Math.max(g - 30, 0))}${decimalToHex(Math.max(b - 30, 0))}`

        if (iteration > 10) {
            return [...initialValue, nc]
        } else {
            return someColorStuff(nc, [...initialValue, nc], iteration + 1);
        }

    } catch (e) {
        console.log(e)
        return initialValue;
    }
}

function decimalToHex(d: any, padding = 2) {
    var hex = Number(d).toString(16);
    padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

    while (hex.length < padding) {
        hex = "0" + hex;
    }

    return hex;
}



export function chartTheme(config: any, screenWith?: number) {

    const primary = config?.palette?.primary?.main ?? "#252525";

    let colors = chartColors;

    if (colors.length == 0) colors = [config?.palette?.primary?.main ?? "#252525", config?.palette?.secondary?.main ?? "#525252"];

    const secondary = config?.palette?.secondary?.main ?? "#525252";
    const text = config?.palette?.text?.main ?? "#525252";
    // *
    // * Typography
    // *
    const sansSerif = "'Gill Sans', 'Seravek', 'Trebuchet MS', sans-serif";
    const letterSpacing = "normal";
    const fontSize = 14;
    // *
    // * Layout
    // *
    const baseProps = {
        width: screenWith ?? 800,
        height: 300,
        padding: 50,
        colorScale: colors
    };
    // *
    // * Labels
    // *
    const baseLabelStyles = {
        fontFamily: sansSerif,
        fontSize: "12px",
        letterSpacing,
        padding: 10,
        fill: text,
        stroke: "transparent"
    };

    const assign = (a: any, b: any, c?: any) => ({ ...a, ...b, ...(c ?? {}) })

    const centeredLabelStyles = assign({ textAnchor: "middle" }, baseLabelStyles);
    // *
    // * Strokes
    // *
    const strokeLinecap = "round";
    const strokeLinejoin = "round";

    const theme = {
        area: assign(
            {
                style: {
                    data: {
                        fill: primary
                    },
                    labels: baseLabelStyles
                }
            },
            baseProps
        ),
        axis: assign(
            {
                style: {
                    axis: {
                        fill: "transparent",
                        stroke: primary,
                        strokeWidth: 1,
                        strokeLinecap,
                        strokeLinejoin
                    },
                    axisLabel: assign({}, centeredLabelStyles, {
                        padding: 25
                    }),
                    grid: {
                        fill: "none",
                        stroke: "none",
                        pointerEvents: "painted"
                    },
                    ticks: {
                        fill: "transparent",
                        size: 1,
                        stroke: "transparent"
                    },
                    tickLabels: baseLabelStyles
                }
            },
            baseProps
        ),
        bar: assign(
            {
                style: {
                    data: {
                        fill: primary,
                        padding: 8,
                        strokeWidth: 0
                    },
                    labels: baseLabelStyles
                }
            },
            baseProps
        ),
        boxplot: assign(
            {
                style: {
                    max: { padding: 8, stroke: primary, strokeWidth: 1 },
                    maxLabels: assign({}, baseLabelStyles, { padding: 3 }),
                    median: { padding: 8, stroke: primary, strokeWidth: 1 },
                    medianLabels: assign({}, baseLabelStyles, { padding: 3 }),
                    min: { padding: 8, stroke: primary, strokeWidth: 1 },
                    minLabels: assign({}, baseLabelStyles, { padding: 3 }),
                    q1: { padding: 8, fill: secondary },
                    q1Labels: assign({}, baseLabelStyles, { padding: 3 }),
                    q3: { padding: 8, fill: secondary },
                    q3Labels: assign({}, baseLabelStyles, { padding: 3 })
                },
                boxWidth: 20
            },
            baseProps
        ),
        candlestick: assign(
            {
                style: {
                    data: {
                        stroke: primary,
                        strokeWidth: 1
                    },
                    labels: assign({}, baseLabelStyles, { padding: 5 })
                },
                candleColors: {
                    positive: "#ffffff",
                    negative: primary
                }
            },
            baseProps
        ),
        chart: baseProps,
        errorbar: assign(
            {
                borderWidth: 8,
                style: {
                    data: {
                        fill: "transparent",
                        stroke: primary,
                        strokeWidth: 2
                    },
                    labels: baseLabelStyles
                }
            },
            baseProps
        ),
        group: assign(
            {
                colorScale: colors
            },
            baseProps
        ),
        histogram: assign(
            {
                style: {
                    data: {
                        fill: secondary,
                        stroke: primary,
                        strokeWidth: 2
                    },
                    labels: baseLabelStyles
                }
            },
            baseProps
        ),
        legend: {
            colorScale: colors,
            gutter: 10,
            orientation: "vertical",
            titleOrientation: "top",
            style: {
                data: {
                    type: "circle"
                },
                labels: baseLabelStyles,
                title: assign({}, baseLabelStyles, { padding: 5 })
            }
        },
        line: assign(
            {
                style: {
                    data: {
                        fill: "transparent",
                        stroke: primary,
                        strokeWidth: 2
                    },
                    labels: baseLabelStyles
                }
            },
            baseProps
        ),
        pie: {
            style: {
                data: {
                    padding: 10,
                    stroke: "transparent",
                    strokeWidth: 1
                },
                labels: assign({}, baseLabelStyles, { padding: 20 })
            },
            colorScale: colors,
            width: 400,
            height: 400,
            padding: 50
        },
        scatter: assign(
            {
                style: {
                    data: {
                        fill: primary,
                        stroke: "transparent",
                        strokeWidth: 0
                    },
                    labels: baseLabelStyles
                }
            },
            baseProps
        ),
        stack: assign(
            {
                colorScale: colors
            },
            baseProps
        ),
        tooltip: {
            style: assign({}, baseLabelStyles, { padding: 0, pointerEvents: "none" }),
            flyoutStyle: {
                stroke: primary,
                strokeWidth: 1,
                fill: "#f0f0f0",
                pointerEvents: "none"
            },
            flyoutPadding: 5,
            cornerRadius: 5,
            pointerLength: 10
        },
        voronoi: assign(
            {
                style: {
                    data: {
                        fill: "transparent",
                        stroke: "transparent",
                        strokeWidth: 0
                    },
                    labels: assign({}, baseLabelStyles, { padding: 5, pointerEvents: "none" }),
                    flyout: {
                        stroke: primary,
                        strokeWidth: 1,
                        fill: "#f0f0f0",
                        pointerEvents: "none"
                    }
                }
            },
            baseProps
        )
    };
    return theme;
}


export function meterColor(meter_id: number, meter_type: string, isTheOnlyOneOfItsKind = false): string {

    if(isTheOnlyOneOfItsKind && meter_type === "ACS") return "#ff0000"
    if(isTheOnlyOneOfItsKind && meter_type === "AF") return "#0000ff"

    if(meter_type === "ACS") return reds[meter_id % (reds.length - 1)];
    if(meter_type === "AF") return blues[meter_id % (blues.length - 1)];

    return chartColors[meter_id % (chartColors.length - 1)];
}

export const chartColors = ["#0000FF", "#8A2BE2", "#A52A2A", "#DC143C", "#00008B", "#008B8B", "#006400", "#8B008B", "#556B2F", "#9932CC", "#8B0000", "#483D8B", "#2F4F4F", "#2F4F4F", "#9400D3", "#696969", "#696969", "#B22222", "#228B22", "#008000", "#4B0082", "#800000", "#0000CD", "#C71585", "#191970", "#000080", "#808000", "#6B8E23", "#800080", "#663399", "#4169E1", "#8B4513", "#2E8B57", "#A0522D", "#6A5ACD", "#708090", "#708090", "#4682B4", "#008080"]

const reds = [
    "#800000",
    "#8B0000",
    "#A52A2A",
    "#B22222",
    "#DC143C",
    "#FF0000",
    "#FF6347",
    "#FF7F50",
    "#CD5C5C",
    "#F08080",
    "#E9967A",
    "#FA8072",
    "#FFA07A",
    "#FF4500",
    "#FF8C00",
    "#FFA500",
    "#FFD700",
    "#B8860B",
]

const blues = [
    "#20B2AA",
 	"#2F4F4F",
 	"#008080",
 	"#008B8B",
 	"#00FFFF",
 	"#00FFFF",
 	"#E0FFFF",
 	"#00CED1",
 	"#40E0D0",
 	"#48D1CC",
 	"#AFEEEE",
 	"#7FFFD4",
 	"#B0E0E6",
 	"#5F9EA0",
 	"#4682B4",
 	"#6495ED",
 	"#00BFFF",
 	"#1E90FF",
 	"#ADD8E6",
 	"#87CEEB",
 	"#87CEFA",
 	"#191970",
 	"#000080",
 	"#00008B",
 	"#0000CD",
 	"#0000FF",
 	"#4169E1",
]


const co = [
    "#FAEBD7",
    "#00FFFF",
    "#7FFFD4",
    "#0000FF",
    "#8A2BE2",
    "#A52A2A",
    "#DEB887",
    "#5F9EA0",
    "#7FFF00",
    "#D2691E",
    "#FF7F50",
    "#6495ED",
    "#FFF8DC",
    "#DC143C",
    "#00FFFF",
    "#00008B",
    "#008B8B",
    "#B8860B",
    "#A9A9A9",
    "#A9A9A9",
    "#006400",
    "#BDB76B",
    "#8B008B",
    "#556B2F",
    "#FF8C00",
    "#9932CC",
    "#8B0000",
    "#E9967A",
    "#8FBC8F",
    "#483D8B",
    "#2F4F4F",
    "#2F4F4F",
    "#00CED1",
    "#9400D3",
    "#FF1493",
    "#00BFFF",
    "#696969",
    "#696969",
    "#1E90FF",
    "#B22222",
    "#FFFAF0",
    "#228B22",
    "#FF00FF",
    "#DCDCDC",
    "#F8F8FF",
    "#FFD700",
    "#DAA520",
    "#808080",
    "#808080",
    "#008000",
    "#ADFF2F",
    "#F0FFF0",
    "#FF69B4",
    "#CD5C5C",
    "#4B0082",
    "#FFFFF0",
    "#F0E68C",
    "#E6E6FA",
    "#FFF0F5",
    "#7CFC00",
    "#FFFACD",
    "#ADD8E6",
    "#F08080",
    "#E0FFFF",
    "#FAFAD2",
    "#D3D3D3",
    "#D3D3D3",
    "#90EE90",
    "#FFB6C1",
    "#FFA07A",
    "#20B2AA",
    "#87CEFA",
    "#778899",
    "#778899",
    "#B0C4DE",
    "#FFFFE0",
    "#00FF00",
    "#32CD32",
    "#FAF0E6",
    "#FF00FF",
    "#800000",
    "#66CDAA",
    "#0000CD",
    "#BA55D3",
    "#9370DB",
    "#3CB371",
    "#7B68EE",
    "#00FA9A",
    "#48D1CC",
    "#C71585",
    "#191970",
    "#F5FFFA",
    "#FFE4E1",
    "#FFE4B5",
    "#FFDEAD",
    "#000080",
    "#FDF5E6",
    "#808000",
    "#6B8E23",
    "#FFA500",
    "#FF4500",
    "#DA70D6",
    "#EEE8AA",
    "#98FB98",
    "#AFEEEE",
    "#DB7093",
    "#FFEFD5",
    "#FFDAB9",
    "#CD853F",
    "#FFC0CB",
    "#DDA0DD",
    "#B0E0E6",
    "#800080",
    "#663399",
    "#FF0000",
    "#BC8F8F",
    "#4169E1",
    "#8B4513",
    "#FA8072",
    "#F4A460",
    "#2E8B57",
    "#FFF5EE",
    "#A0522D",
    "#C0C0C0",
    "#87CEEB",
    "#6A5ACD",
    "#708090",
    "#708090",
    "#FFFAFA",
    "#00FF7F",
    "#4682B4",
    "#D2B48C",
    "#008080",
    "#D8BFD8",
    "#FF6347",
    "#40E0D0",
    "#EE82EE",
    "#F5DEB3",
    "#FFFFFF",
    "#F5F5F5",
    "#FFFF00",
    "#9ACD32"
]