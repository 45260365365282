import { AppBar, Avatar, Button, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import { AccountCircle, ExitToApp } from '@material-ui/icons';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConfigContext, history } from '../App';

const Im = styled.img`
  height: 100%;
  width: 300px;
  margin-left: -24px;
  object-fit: scale-down;
  background: linear-gradient(90deg, rgba(1,1,1,0.5) 0%, rgba(60,75,100,0) 100%);
`

export function Header(props: {}) {
    const config = useContext(ConfigContext);
    
    return <AppBar position="static" >
    <Toolbar>
    <Grid container justify="space-between"  xs={12}>
        <Grid xs={2}>
            <Im src={config?.logo_url} alt="Logo"/>
        </Grid>
        <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => {
                    window.sessionStorage.removeItem("token")
                    history.push("/login")
                }}
                color="inherit"
              >
                <ExitToApp />
              </IconButton>
    </Grid>
    </Toolbar>
  </AppBar>
    


}