import {BASE_URL, history} from "../App";

export type PaginatedList<T> = {
    sublist: T[],
    count: number,
    perPage: number,
    page: number
  }

export const remoteFetchData: <T>(url: string) => Promise<T | string> = <T>(url: string) =>
  get(url)
    .then(r => r.data as T)
    .catch(e => e.toString());

export const remoteFetchAttributes: <T>(url: string) => Promise<T | string> = <T>(url: string) =>
  get(url)
    .then(r => {
      return r.data.attributes as T;
    })
    .catch(e => e.toString());

//  T e' g attributes
export const fetchPaginatedCollection: <T>(
  url: string,
  onFetch?: (items: T[]) => void,
  postProcessor?: (items: T[]) => T[]
  ) => (selectedPage: number, perPage: number) => Promise<PaginatedList<T> | string> = <T>(url: string, onFetch?: (items: T[]) => void, postProcessor?: (items: T[]) => T[]) => async (selectedPage: number, perPage: number) => {
  try {
    const response = await get(`${url}${url.includes("?") ? "&" : "?"}per_page=${perPage}&page=${selectedPage}`);
    if(response.status > 300) {
      return `Error, status ${response.status}`;
    }

    const sublist = (response.data).map((a: { attributes: T }) => a.attributes) as T[];
    const page = response.meta.paginate.page;

    const count = response.meta.count;

    (onFetch && onFetch(sublist));

    if(postProcessor) return {
      sublist: postProcessor(sublist),
      count,
      perPage,
      page
    }

    return {
      sublist,
      count,
      perPage,
      page
    }

  } catch (e) {
    return e.toString();
  }
};

export const fetchPaginatedCollectionNoAttributes: <T>(url: string, onFetch?: (items: T[] | string) => void) => (selectedPage: number, perPage: number) => Promise<PaginatedList<T> | string> = <T>(url: string, onFetch?: (items: T[] | string) => void) => async (selectedPage: number, perPage: number) => {
  try {
    const response = await get(`${url}?per_page=${perPage}&page=${selectedPage}`);
    if(response.status > 300) {
      (onFetch && onFetch(`Error, status ${response.status}`));
      return `Error, status ${response.status}`;
    }

    const sublist = (response.data) as T[];
    const page = response.meta.paginate.page;

    const count = response.meta.count;

    (onFetch && onFetch(sublist));

    return {
      sublist,
      count,
      perPage,
      page
    }

  } catch (e) {
    (onFetch && onFetch(e.toString()));
    return e.toString();
  }
};

//  T e' g attributes
export const fetchSinglePageCollection: <T>(url: string) => (selectedPage: number, perPage: number) => Promise<PaginatedList<T> | string> = (url: string) => async <T>(selectedPage: number, perPage: number) => {
  try {
    const response = await get(`${url}${url.includes("?") ? "&" : "?"}per_page=${perPage}&page=${selectedPage}`);
    if(response.status > 300) {
      return `Error, status ${response.status}`;
    }

    const sublist = (response.data).map((a: { attributes: T }) => a.attributes) as T[];
    const page = 1;

    const count = sublist.length;

    return {
      sublist,
      count,
      perPage,
      page
    }

  } catch (e) {
    return e.toString();
  }
};


export const post = async (body: any, url: string) => {
  try {
    return await (await postRaw(body, url)).json();
  } catch (e) {
    return e.toString();
  }

};

export const postRaw = async (body: any, url: string) => {
  try {
    const response = await fetch(BASE_URL + url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${window.sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(body)
    });
    if(response.status === 401) history.push("/login");
    if(response.status === 500) return "500 internal server error"
    return response;
  } catch (e) {
    return e.toString();
  }

};

export const postFormData = async (body: FormData, url: string) => {
  const response = await fetch(BASE_URL + url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      // 'Content-Type': 'application/vnd.api+json',
      'Authorization': `Bearer ${window.sessionStorage.getItem('token')}`
    },
    body: body
  });
  if(response.status === 401) history.push("/login");
  return response;
};

export const putFormData = async (body: FormData, url: string) => {
  const response = await fetch(BASE_URL + url, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      // 'Content-Type': 'application/vnd.api+json',
      'Authorization': `Bearer ${window.sessionStorage.getItem('token')}`
    },
    body: body
  });
  if(response.status === 401) history.push("/login");
  return response;
};

export const put = async (body: any, url: string) => {
  return (putRaw(body, url).then((r) => r.json()));
};

export const putStatusCode = async (body: any, url: string) => {
  return (await putRaw(body, url)).status;
};

export const putRaw = async (body: any, url: string) => {
  const response = await fetch(BASE_URL + url, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Authorization': `Bearer ${window.sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(body)
  });
  if(response.status === 401) history.push("/login");
  return response;
};

export const httpDelete = async (url: string) => {
  const response = await fetch(BASE_URL + url, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Authorization': `Bearer ${window.sessionStorage.getItem('token')}`
    },
  });
  if(response.status === 401) history.push("/login");
  return response;
};

export const get = async (url: string) => {
  return getNoBaseUrl(BASE_URL + url);
};

export const getNoBaseUrl = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Authorization': `Bearer ${window.sessionStorage.getItem('token')}`
    },
  });
  if(response.status === 401) {
    await httpDelete("/api/v1/logout");
    window.sessionStorage.clear() 
    history.push("/login");
   }

  try {
    return await response.json();
  } catch (e) {
    return e.toString();
  }

};

export const getBody = async (url: string) => {
  const response = await fetch(BASE_URL + url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Authorization': `Bearer ${window.sessionStorage.getItem('token')}`
    },
  });
  if(response.status === 401) history.push("/login");

  try {
    return response.text();
  } catch (e) {
    return e.toString();
  }

};