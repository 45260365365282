import moment from "moment";

function chunk<T>(arr: Array<T>, chunkSize: number): Array<Array<T>> {
    return arr.reduce((prevVal: any, currVal: any, currIndx: number, array: Array<T>) =>
      !(currIndx % chunkSize) ?
      prevVal.concat([array.slice(currIndx, currIndx + chunkSize)]) :
      prevVal, []);
  }

export function prune(data: any[]): any[] {
    const readings_by_day = data.reduce((acc, x) => {
        const cons = [...(acc[x.time || x.read_time] ?? []), x]
        acc[x.time || x.read_time] = cons
        return acc
    }, {})


    if(Object.keys(readings_by_day).length < 10) return data;
    
    const newData = chunk(Object.keys(readings_by_day), 2).flatMap((c) => {
        const c1 = c[0];
        const c2 = c[c.length -1];
        const readings1 = readings_by_day[c1]
        const readings2ByMeterId = readings_by_day[c2]?.reduce((acc: any, x:any) => {
            acc[x.meter_id] = x
            return acc;
        }, {}) ?? {}


        const reducedThings = readings1.reduce((acc: any, x: any) => {
            return [...acc, [x, readings2ByMeterId[x.meter_id]]]
        }, []).map(([c1, c2]: [any, any]) => reduceConsumptions(c1, c2)).filter((it: any) => it);
        
        return reducedThings
    }).sort((a, b) => (a.time < b.time) ? -1 : (a.time > b.time) ? 1 : 0)


    return prune(newData)
    //return newData
}

export function pruneChunk(data: any[], chunkSize: number): any[] {
  const readings_by_day = data.reduce((acc, x) => {
      const cons = [...(acc[x.time || x.read_time] ?? []), x]
      acc[x.time || x.read_time] = cons
      return acc
  }, {})


  if(chunkSize < 7) return data;
  
  const newData = chunk(Object.keys(readings_by_day), chunkSize).flatMap((c) => {
      const c1 = c[0];
      const c2 = c[c.length -1];
      const readings1 = readings_by_day[c1]
      const readings2ByMeterId = readings_by_day[c2]?.reduce((acc: any, x:any) => {
          acc[x.meter_id] = x
          return acc;
      }, {}) ?? {}


      const reducedThings = readings1.reduce((acc: any, x: any) => {
          return [...acc, [x, readings2ByMeterId[x.meter_id]]]
      }, []).map(([c1, c2]: [any, any]) => reduceConsumptions(c1, c2)).filter((it: any) => it);
      
      return reducedThings
  }).sort((a, b) => (a.time < b.time) ? -1 : (a.time > b.time) ? 1 : 0)

  return newData
}

export function reduceConsumptions(c1: C, c2: C | undefined): any {
    if(!c2) return undefined;
    const time = [c1.time, c2.time].sort().reverse()
    const value = c1.value + c2.value
  
    var label = ""
    // try {
    //   const lp = c2.label.split("\n");
    //   const lpp = lp[2].split(" ");
    //   lpp[0] = value.toPrecision(2);
    //   label = [lp[0], lp[1], lpp.join(" "), lp[3]].join("\n")

    // } catch(e) {}
    
    const it: any = {...c2, time: time[0], value, label, initial_reading_values: c1.initial_reading_values, initial_reading_time: c1.initial_reading_time}

    if (Object.keys(it.initial_reading_values).length > 0 && Object.keys(it.final_reading_values).length > 0) {
      const initialV = it.initial_reading_values[Object.keys(it.initial_reading_values)[0]]
      const finalV = it.final_reading_values[Object.keys(it.final_reading_values)[0]]

      if(Object.keys(it.initial_reading_values).length > 1) {
          const initialV = it.initial_reading_values[Object.keys(it.initial_reading_values)[0]]
          const finalV = it.final_reading_values[Object.keys(it.final_reading_values)[0]]
          it["v1"] = (it.k ?? 1) * ((Math.round((finalV + Number.EPSILON) * 1000) / 1000) - (Math.round((initialV + Number.EPSILON) * 1000) / 1000))
          const initialV2 = it.initial_reading_values[Object.keys(it.initial_reading_values)[0]]
          const finalV2 = it.final_reading_values[Object.keys(it.final_reading_values)[0]]
          it["v2"] = (it.k ?? 1) * ((Math.round((finalV2 + Number.EPSILON) * 1000) / 1000) - (Math.round((initialV2 + Number.EPSILON) * 1000) / 1000))
      }

      const room = it["room"];
      it["value"] = (it.k ?? 1) * ((Math.round((finalV + Number.EPSILON) * 1000) / 1000) - (Math.round((initialV + Number.EPSILON) * 1000) / 1000));
      it["label"] = `${it["serial-number"] ? `${it["serial-number"]}\n` : ""}${moment(it["time"]).format("DD/MM")}\n${it["value"].toFixed(2)} ${it["measure_unit"] ?? "HCA"} ${it["measure_unit"] == "HCA" ? `(K: ${it["k"]})` : ""}${room ? `\n${room}` : ""}`
      
  }

  return it



}


export function dc<T>(a: T): T | undefined {
  if(!a) return undefined;
  return JSON.parse(JSON.stringify(a));
}


type C = {
    "time": string,
    "serial-number": string,
    "value": number,
    "final_reading_values": any,
    "final_reading_time": any,
    "initial_reading_values": any,
    "initial_reading_time": any,
    "label": string
}

const cons_a = {
    "final_reading_time": "2021-04-17T00:00:00.000000Z",
    "final_reading_values": {
      "volume": 1716.5638783065945
    },
    "floor": "Piano 1",
    "id": 350776,
    "immobile": "Becchetti Luciano",
    "initial_reading_time": "2021-04-16T00:00:00.000000Z",
    "initial_reading_values": {
      "volume": 1707.5296153846155
    },
    "k": 1.79,
    "meta": {
      "interpolated": false
    },
    "meter_id": 1043367,
    "meter_serial_number": "52604570",
    "meter_type": "RIPARTITORE",
    "room": null,
    "time": "2021-04-16T00:00:00.000000Z",
    "serial-number": "52604570",
    "value": 16.163699999999952,
    "label": "52604570\n16/04\nvolume: 16.16"
  }
  

  const read_a = {
    "delete_groups": null,
    "flat_number": "1",
    "floor": "Piano 1",
    "has_errors": false,
    "id": 2617362,
    "immobile": "Becchetti Luciano",
    "meter_id": 1043367,
    "read_time": "2021-04-15T10:55:48.000000Z",
    "type": "RIPARTITORE",
    "update_groups": null,
    "value": 1702,
    "label": "15/04\nvolume: 1702.00",
    "serial_number": "52604570"
  }