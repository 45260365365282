import { Accordion, AccordionDetails, AccordionSummary, Grid, TableSortLabel, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useMemo } from 'react';
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryBar, VictoryZoomContainer, VictoryTooltip } from 'victory';
import { chartTheme } from '../../utlis/chartsStyle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { dynamicSort, HomePageAction, HomePageState } from './homePageViewModel';
import { pruneConsumptions } from '../../utlis/pruneDates';



export function Consumptions(props: { consumptions: any[], state: HomePageState, dispatch: React.Dispatch<HomePageAction>, cumulativeConsumptions: any[], config: any }) {

  const filteredCs = useMemo(() => {
    return pruneConsumptions(props.consumptions, props.state.scale, props.state)
  }, props.consumptions)

  return <Grid container justify="center" style={{ padding: 20 }}>
    <Grid xs={12}>
      <Typography variant="h2" style={{ marginTop: "3rem" }}>
        {`Consumi`}
      </Typography>
      <Typography variant="h4">{`Meter ${props.state?.selectedMeter?.attributes?.serialNumber ?? ''} 
    ${props.state?.selectedMeter?.attributes?.meterHeatDivider?.room ?? ''}
    ${props.state?.selectedImmobile?.data.floor ? `, ${props.state?.selectedImmobile?.data.floor}` : ''}
    `
      }</Typography>
    </Grid>
    <Grid xs={12} md={6} >
      <VictoryChart
        domainPadding={100}
        theme={chartTheme(props.config)}
      >
        <VictoryAxis
          tickFormat={(x) => moment(x).format("DD/MM")}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(x: number) => Math.round(x * 1000) / 1000}
        />
        <VictoryBar
          data={filteredCs}
          style={
            {
              data: {
                fill: props.state.selectedMeter?.type == "AF"
                  ? "#0000ff"
                  : props.state.selectedMeter?.type == "ACS"
                    ? "#ff0000"
                    : undefined
              }
            }
          }
          labelComponent={<VictoryTooltip />}
          x={"time"}
          y={"value"}
        />
      </VictoryChart>
    </Grid>
    <Grid item sm={12} md={6} className="scroll">
      <Accordion>

        <AccordionDetails>
          <Table aria-label="consumptions" className="scroll">
            <TableHead>
              <TableRow>
                <TableCell>
                  <SortHeader state={props.state} dispatch={props.dispatch} columnName={"time"} columnLabel={"Data"} />
                </TableCell>
                <TableCell align="right">
                  <SortHeader state={props.state} dispatch={props.dispatch} columnName={"value"} columnLabel={"Consumo"} />
                </TableCell>
                <TableCell align="right">Unità di misura</TableCell>
                {props.state.selectedMeter!.type == "RIPARTITORE" && <TableCell align="right">K</TableCell>}

              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCs.sort(dynamicSort(props.state.consumptionsSMSortedBy, props.state.consuptionsSMSortAsc)).map((c: any) => (
                <TableRow key={c.id}>
                  <TableCell component="th" scope="row">
                    {moment(c.time).format("DD/MM/yyyy")}
                  </TableCell>
                  <TableCell align="right">{c.v1 != undefined
                    ? `Calorie ${(Math.round(((c.v2) + Number.EPSILON) * 1000) / 1000)}, Frigorie ${(Math.round(((c.v1) + Number.EPSILON) * 1000) / 1000)}`
                    : (Math.round(((c.value) + Number.EPSILON) * 1000) / 1000)}</TableCell>
                  <TableCell align="right">{c.measure_unit}</TableCell>
                  {props.state.selectedMeter!.type == "RIPARTITORE" && <TableCell align="right">{c.k}</TableCell>}

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Grid>
  </Grid>
}

export function SortHeader(props: { state: HomePageState, dispatch: React.Dispatch<HomePageAction>, columnName: string, columnLabel: string }) {
  return <TableSortLabel
    active={props.state.consumptionsSMSortedBy === props.columnName}
    direction={props.state.consuptionsSMSortAsc ? "asc" : 'desc'}
    onClick={() => {
      if (props.state.consumptionsSMSortedBy === props.columnName) {
        props.dispatch({ type: "setconsuptionsSMSortAsc", value: !props.state.consuptionsSMSortAsc })
      } else {
        props.dispatch({ type: "setconsumptionsSMSortedBy", value: props.columnName })
      }
    }}
  >
    {props.columnLabel}

  </TableSortLabel>

}