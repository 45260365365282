import { CircularProgress, Grid, GridList, GridListTile, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useMemo } from 'react';
import { CondominiumNode, Immobile, SubCondominium } from '../../models/condominium';
import { Meter } from '../../models/meter';
import { HomePageState } from '../homePage/homePageViewModel';
import { MeterSelectionForm } from '../homePage/meterSelectionForm';

type Props = {
    state: HomePageState
    dispatch: any,
    config: any
}


export function AlarmPage(props: Props) {

    const alarms = (meter: any) => {
        return meter.attributes?.activeAlarms ?? {}
    };

    const meters = useMemo(() => {

        const state = props.state;
        if (!state.selectedCondominium) return [];

        const selectedSubCondominium = props.state.selectedSubCondominium;
        const selectedImmobile = props.state.selectedImmobile;

        const immobiles = new Set(state.selectedCondominium.children.flatMap((sc: CondominiumNode) => sc.children.flatMap((i: CondominiumNode) => (i as Immobile).data.name)))

        return props.state.meters
        .filter((m: Meter) => m.attributes.activeAlarms && m.attributes.activeAlarms !== {})
        .filter((m: Meter) => immobiles.has(m.attributes.immobile))
        .filter((m: Meter) => {
            if(!selectedSubCondominium) return true;
            return m.attributes.subCondominium === selectedSubCondominium.data.name;
        }).filter((m: Meter) => {
            if(!selectedImmobile) return true;
            return m.attributes.immobile === selectedImmobile.data.name;
        })
    }, [
        props.state
    ])

    const isLoadingSomething = props.state.loadingData || props.state.loadingReading || props.state.loadingCumulativeConsumptions || props.state.loadingMeters

    return <Grid container>
        <MeterSelectionForm state={props.state} dispatch={props.dispatch} withMeter={false} />
        {isLoadingSomething && <Grid style={{ marginTop: "3rem" }} container justify="center">
            <CircularProgress />
        </Grid>}


        {!isLoadingSomething && meters.filter((meter: Meter) => Object.values(alarms(meter)).length > 0).length == 0 && <Typography variant="h4" style={{ paddingTop: "2rem" }}> Nessun Errore </Typography>}
        {
            !isLoadingSomething && meters.filter((meter: Meter) => Object.values(alarms(meter)).length > 0).map((meter: Meter) => {
                return <Grid style={{ marginTop: 16 }} item xs={12} key={meter.attributes.serialNumber}>
                    <Typography variant="h5"> {meter.attributes.type} {meter.attributes.serialNumber}</Typography>
                    {Object.keys(alarms(meter)).length == 0 && <Typography variant="h6"> Nessun errore rilevato </Typography>}
                    <GridList cellHeight={100} style={{ width: '100%' }} cols={window.innerWidth > 700 ? 3 : 1}>
                        {Object.keys(alarms(meter)).map((key) => (
                            <GridListTile key={key} cols={1}>
                                <Paper elevation={5} style={{ padding: "1rem", minWidth: 300 }}>
                                    <Typography variant="h5">{prettify(key)}</Typography>
                                    {meter?.attributes?.immobile && <Typography variant="h6">{meter.attributes.immobile}</Typography>}
                                    <Typography variant="h6">{moment(alarms(meter)[key].alarm_opened_at).format("DD/MM/yyyy")}</Typography>
                                </Paper>
                            </GridListTile>
                        ))}
                    </GridList>

                </Grid>

            })}




    </Grid>
}

function prettify(s: string) {
    return s.replaceAll("_", " ").replaceAll("-", " ")
}